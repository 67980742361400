@import "trix/dist/trix";

:root,
.c8o2 {
  // layout
  --sidebar-width: 0;
  --header-height: 4rem;

  @include media-breakpoint-up(md) {
    // Change sidebar_size_controller.js defaultWidth if this changes
    --sidebar-width: 250px;
  }

  // setup primary.
  --bs-primary-h: 200;
  --bs-primary-s: 85%;
  --bs-primary-l: 31%;
  --bs-primary-hsl: var(--bs-primary-h), var(--bs-primary-s), var(--bs-primary-l);
  --bs-primary: hsla(var(--bs-primary-hsl, 1));
  --bs-primary-light: hsla(var(--bs-primary-hsl), 0.45);
  --bs-primary-lighter: hsla(var(--bs-primary-hsl), 0.04);
  --bs-primary-lightest: hsla(var(--bs-primary-hsl), 0.033);
  --bs-primary-lightest-rgb: mix(hsl(var(--bs-primary-hsl)), hsl(var(--bs-primary-hsl)));
  // setup secondary.
  --bs-secondary-h: 31;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 48%;
  --bs-secondary-hsl: var(--bs-secondary-h), var(--bs-secondary-s), var(--bs-secondary-l);
  --bs-secondary: hsla(var(--bs-secondary-hsl, 1));
  --bs-secondary-light: hsla(var(--bs-secondary-hsl), 0.45);
  --bs-secondary-lighter: hsla(var(--bs-secondary-hsl), 0.04);
  --bs-secondary-lightest: hsla(var(--bs-secondary-hsl), 0.033);
  --bs-secondary-lightest-rgb: mix(hsl(var(--bs-secondary-hsl)), hsl(var(--bs-secondary-hsl)));
  // states.
  --bs-success: #3bb273;
  --bs-info: #084c61;
  --bs-warning: #edae49;
  --bs-danger: #eb8a90;
  --bs-light: #eef2f7;
  --bs-lightish: #cbd0d6;
  --bs-dark: #313a46;
  --bs-darkish: #646b74;
  --bs-gray-rgb: 108, 117, 125;
  --bs-primary-rgb: 94, 171, 206;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-font-sans-serif: poppins, sans-serif;
  --sl-font-sans: var(--bs-body-font-family);
  --bs-font-fixed-width: "Courier New", courier;
  --sl-font-mono: var(--bs-font-fixed-width);
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-header-bg: #ffffff;
  --bs-body-color: #303940;
  --bs-body-color-light: var(--bs-primary-light);
  --bs-body-bg: hsla(var(--bs-primary-hsl), 0.001);
  --bs-body-font-family: poppins, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-font-size-xlarge: 1.2rem;
  --sl-font-size-2x-large: var(--bs-font-size-xlarge);
  --bs-font-size-larger: 1.1rem;
  --sl-font-size-x-large: var(--bs-font-size-larger);
  --bs-font-size-large: 1rem;
  --sl-font-size-large: var(--bs-font-size-large);
  --bs-font-size-body: 0.9rem;
  --sl-font-size-medium: var(--bs-font-size-body);
  --bs-font-size-small: 0.85rem;
  --sl-font-size-small: var(--bs-font-size-small);
  --bs-font-size-smaller: 0.8rem;
  --sl-font-size-x-small: var(--bs-font-size-smaller);
  --bs-font-size-smallest: 0.75rem;
  --sl-font-size-2x-small: var(--bs-font-size-smallest);
  --bs-body-font-weight-light: 300;
  --sl-font-weight-light: var(--bs-body-font-weight-light);
  --bs-body-font-weight-medium: 400;
  --sl-font-weight-normal: var(--bs-body-font-weight-medium);
  --bs-body-font-weight: var(--bs-body-font-weight-medium);
  --bs-body-font-weight-bold: 700;
  --sl-font-weight-semibold: var(--bs-body-font-weight-bold);
  --bs-body-font-weight-fat: 900;
  --sl-font-weight-bold: var(--bs-body-font-weight-fat);
  --bs-spacer-xlarge: 0.85rem;
  --bs-spacer-larger: 0.8rem;
  --bs-spacer-large: 0.75rem;
  --bs-spacer: 0.5rem;
  --bs-spacer-small: 0.25rem;
  --bs-spacer-smaller: 0.2rem;
  --bs-spacer-smallest: 0.1rem;
  --bs-radius: var(--bs-spacer-small);

  // ensure our font settings aren't overwritten by the default dark mode stylesheet
  .sl-theme-dark {
    --bs-font-sans-serif: poppins, sans-serif;
    --sl-font-sans: var(--bs-body-font-family);
    --bs-font-fixed-width: "Courier New", courier;
    --sl-font-mono: var(--bs-font-fixed-width);
    --bs-body-font-family: poppins, sans-serif;
    --bs-body-font-size: 1rem;
    --bs-font-size-xlarge: 1.2rem;
    --sl-font-size-2x-large: var(--bs-font-size-xlarge);
    --bs-font-size-larger: 1.1rem;
    --sl-font-size-x-large: var(--bs-font-size-larger);
    --bs-font-size-large: 1rem;
    --sl-font-size-large: var(--bs-font-size-large);
    --bs-font-size-body: 0.9rem;
    --sl-font-size-medium: var(--bs-font-size-body);
    --bs-font-size-small: 0.85rem;
    --sl-font-size-small: var(--bs-font-size-small);
    --bs-font-size-smaller: 0.8rem;
    --sl-font-size-x-small: var(--bs-font-size-smaller);
    --bs-font-size-smallest: 0.75rem;
    --sl-font-size-2x-small: var(--bs-font-size-smallest);
    --bs-body-font-weight-light: 300;
    --sl-font-weight-light: var(--bs-body-font-weight-light);
    --bs-body-font-weight-medium: 400;
    --sl-font-weight-normal: var(--bs-body-font-weight-medium);
    --bs-body-font-weight: var(--bs-body-font-weight-medium);
    --bs-body-font-weight-bold: 700;
    --sl-font-weight-semibold: var(--bs-body-font-weight-bold);
    --bs-body-font-weight-fat: 900;
    --sl-font-weight-bold: var(--bs-body-font-weight-fat);
    --bs-spacer-xlarge: 0.85rem;
    --bs-spacer-larger: 0.8rem;
    --bs-spacer-large: 0.75rem;
    --bs-spacer: 0.5rem;
    --bs-spacer-small: 0.25rem;
    --bs-spacer-smaller: 0.2rem;
    --bs-spacer-smallest: 0.1rem;
  }
}

.c8o2 {
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--bs-primary);
    margin-bottom: 0;

    a {
      text-decoration: none;
    }

    i {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2.5px;
    }
  }

  h1,
  h1 * {
    font-size: var(--bs-font-size-xlarge) !important;
    font-weight: var(--bs-font-weight-light) !important;
  }

  h2,
  h2 * {
    font-size: var(--bs-font-size-larger) !important;
    font-weight: var(--bs-body-font-weight-medium) !important;
    color: var(--bs-secondary);
  }

  h3,
  h3 * {
    font-size: var(--bs-font-size-large) !important;
    font-weight: var(--bs-font-weight-light) !important;
    text-transform: uppercase !important;
  }

  h4,
  h4 * {
    font-size: var(--bs-font-size-body) !important;
    font-weight: var(--bs-font-weight-bold) !important;
    color: var(--bs-secondary);
  }

  h5,
  h5 * {
    font-size: var(--bs-font-size-small) !important;
    font-weight: var(--bs-body-font-weight-light) !important;
    text-transform: uppercase !important;
  }

  h6,
  h6 * {
    font-size: var(--bs-font-size-small) !important;
    font-weight: var(--bs-font-weight-light) !important;
  }

  .trix-button-group--file-tools {
    display: none;
  }

  background-color: var(--bs-primary-lightest);

  .text-right {
    text-align: right;
  }

  .hover-highlight {
    &:hover {
      background-color: var(--bs-primary-lightest);
    }
  }

  &.c8o2 {
    font-size: var(--bs-font-size-body);
  }

  a {
    color: var(--bs-body-color);

    &:hover {
      background-color: var(--bs-primary-lightest);
    }

    &.link-primary {
      color: var(--bs-primary);
    }

    &.link-secondary {
      color: var(--bs-secondary);
    }
  }

  a.plain {
    text-decoration: none;
  }

  .flash-error {
    color: var(--bs-danger);
  }

  .flash-notice {
    color: var(--bs-success);
  }

  label,
  input {
    color: var(--bs-body-color);

    &.filter-field {
      max-width: 16ch;
    }
  }

  .form-check-input:checked {
    background-color: var(--bs-primary);
  }

  .bg-primary {
    background-color: var(--bs-primary);
  }

  .bg-secondary {
    background-color: var(--bs-secondary);
  }

  .bg-info {
    background-color: var(--bs-info);
  }

  .bg-success {
    background-color: var(--bs-success);
  }

  .bg-warning {
    background-color: var(--bs-warning);
  }

  .bg-danger {
    background-color: var(--bs-danger);
  }

  .bg-light {
    background-color: var(--bs-primary-lightest);
  }

  hr {
    color: var(--bs-secondary);
  }

  /*
  btns & forms
   */
  .btn {
    display: inline-block;
    font-weight: var(--bs-body-font-weight-medium);
    cursor: pointer;
    font-size: var(--bs-font-size-body);
    padding-top: 0;
    padding-bottom: -0;
    overflow: hidden;

    &.btn-sm {
      font-weight: var(--bs-body-font-weight-light);
      font-size: var(--bs-font-size-small);
      line-height: var(--bs-font-size-small);
    }

    &.btn-link {
      color: var(--bs-body-color);
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      text-decoration-line: none;
    }

    &:not(.btn-link) {
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 38px;
      border: 1px solid transparent;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.15rem;
      -webkit-transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    }

    &:hover {
      color: var(--bs-body-color);
      background-color: var(--bs-body-bg);
    }

    &.btn-primary {
      background-color: var(--bs-primary);
      color: var(--bs-white);

      &:hover {
        box-shadow: 0 0 15px 0 var(--bs-primary);
        color: var(--bs-white);
      }
    }

    &.btn-secondary {
      background-color: var(--bs-secondary);
      color: var(--bs-white);

      &:hover {
        box-shadow: 0 0 15px 0 var(--bs-secondary);
        color: var(--bs-white);
      }
    }

    &.btn-danger {
      background-color: var(--bs-danger);
      color: var(--bs-white);

      &:hover {
        box-shadow: 0 0 15px 0 var(--bs-danger);
        color: var(--bs-white);
      }
    }

    &.btn-warning {
      background-color: var(--bs-warning);
      color: var(--bs-white);

      &:hover {
        box-shadow: 0 0 15px 0 var(--bs-warning);
        color: var(--bs-white);
      }
    }

    &.btn-success {
      background-color: var(--bs-success);
      color: var(--bs-white);

      &:hover {
        box-shadow: 0 0 15px 0 var(--bs-success);
        color: var(--bs-white);
      }
    }

    &.btn-info {
      background-color: var(--bs-info);
      color: var(--bs-white);

      &:hover {
        box-shadow: 0 0 15px 0 var(--bs-info);
        color: var(--bs-white);
      }
    }

    &.btn-outline-primary {
      border: 1px solid var(--bs-primary);
      color: var(--bs-primary);

      &:hover {
        color: var(--bs-white);
        background-color: var(--bs-primary);
      }

      &.dropdown-toggle.show {
        color: var(--bs-white);
        background-color: var(--bs-primary);
      }
    }

    &.btn-outline-secondary {
      border: 1px solid var(--bs-secondary);
      color: var(--bs-secondary);

      &:hover {
        color: var(--bs-white);
        background-color: var(--bs-secondary);
      }
    }

    &.btn-outline-danger {
      border: 1px solid var(--bs-danger);
      color: var(--bs-danger);

      &:hover {
        color: var(--bs-white);
        background-color: var(--bs-danger);
      }
    }

    &.btn-outline-warning {
      border: 1px solid var(--bs-warning);
      color: var(--bs-warning);

      &:hover {
        color: var(--bs-white);
        background-color: var(--bs-warning);
      }
    }

    &.btn-outline-success {
      border: 1px solid var(--bs-success);
      color: var(--bs-success);

      &:hover {
        color: var(--bs-white);
        background-color: var(--bs-success);
      }
    }

    &.btn-outline-info {
      border: 1px solid var(--bs-info);
      color: var(--bs-info);

      &:hover {
        color: var(--bs-white);
        background-color: var(--bs-info);
      }
    }
  }

  .btn-group {
    .btn {
      height: auto;
    }
  }

  *[class*="btn-outline"] {
    color: var(--bs-primary);
    background-color: var(--bs-white);
    border: 1px solid var(--bs-primary);

    &:hover {
      color: var(--bs-white);
      background-color: var(--bs-primary);
    }
  }

  .form-control {
    padding-top: 0;
    padding-bottom: 0;
    height: 38px;
    line-height: 34px;
  }

  textarea.form-control {
    font-family: var(--bs-font-fixed-width);
    line-height: 1.1rem;
    min-height: 5rem;
  }

  .logo-lg .logo {
    height: auto;
    width: 75%;
  }

  .turbo-progress-bar {
    height: 0.25rem;
    background-color: var(--bg-primary);
  }

  a.navbar-brand img {
    max-height: 64px;
  }

  ul.toolbar-menu {
    li {
      a {
        text-decoration: none;
      }
    }
  }

  form.dropzone {
    margin-top: 2rem !important;
    min-height: 12rem;
    border: 2px dashed var(--bs-primary);
    background-color: var(--bs-primary-lighter);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .caption {
      color: var(--bs-primary-light);
      text-align: center;
    }
  }

  form {
    .multi-select-container {
      max-height: 32rem;
      overflow-y: scroll;
      background-color: var(--bg-white);
    }
  }

  .search-result-item,
  .activity-details,
  .folder-item-details,
  .folder-details,
  .details,
  table {
    a {
      color: var(--bs-secondary);
      text-decoration: none;

      a {
        color: none;
      }
    }

    h5 {
      color: $gray-800;
      font-size: var(--bs-font-size-small);
    }

    h6 {
      color: $gray-600;
      font-size: var(--bs-font-size-small);
    }

    .text {
      color: $gray-400;
      font-size: var(--bs-font-size-small);
    }
  }

  .scrollview-list {
    max-height: 14rem;
    overflow-y: auto;
  }

  .details {
    a {
      color: var(--bs-secondary);
      text-decoration: none;
    }
  }

  .highlight {
    border: 1px solid $info;
  }

  .trix-content {
    font-size: var(--bs-font-size-body);
    font-weight: 300 !important;
  }

  .even {
    background-color: var(--bs-primary-lightest);
  }

  /*
  card
  */
  .card {
    border: 1px solid hsla(var(--bs-primary-hsl), 0.1) !important;
    background-color: var(--bs-white);
    padding: 0rem;
    @include media-breakpoint-up(md) {
      padding: 1rem;
    }
    box-shadow: 0 0 33px 0 rgba(var(--bs-primary-rgb), 0.07) !important;
    min-height: 100%;

    .container-fluid {
      padding: 0;
    }

    &.ragged {
      min-height: fit-content;
    }

    .list-unstyled {
      li {
        &:hover {
          box-shadow: 0 0 33px 0 rgba(var(--bs-primary-rgb), 0.1) !important;
        }
      }
    }
  }

  /**
   * badge
   */
  .badge {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
  }

  /*
  dropdowns
   */
  .dropdown-menu {
    border: 1px solid var(--bs-primary-lighter) !important;
    box-shadow: 0 0 33px 0 rgba(var(--bs-primary-rgb), 0.1) !important;
    padding: 0 !important;
    background-color: var(--bs-white) !important;
    opacity: 0.95;

    li {
      color: var(--bs-body-color);
      font-size: var(--bs-font-size-body);
      padding: 0 !important;
      display: block;

      &:not(:last-child) {
        border-bottom: 1px solid var(--bs-primary-lighter);
      }

      a,
      form {
        padding: 0.66rem !important;
        display: block;

        &:hover {
          background-color: var(--bs-primary-lightest) !important;
        }
      }

      input {
        padding: 0 !important;
      }

      hr {
        display: none;
      }
    }
  }

  li.dropdown-item {
    color: var(--bs-body-color);
    font-size: var(--bs-font-size-body);
    padding: 0 !important;
    display: block;

    &:not(:last-child) {
      border-bottom: 1px solid var(--bs-primary-lighter);
    }

    a,
    form {
      display: block;
      padding: 0.66rem !important;

      &:hover {
        background-color: var(--bs-primary-lightest) !important;
      }
    }

    input {
      padding: 0 !important;
    }

    hr {
      display: none;
    }
  }

  /*
  hr
   */
  hr {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    background-color: var(--bs-primary-light);
  }

  /*
   new_search
    */
  #new_search {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    input[type="search"] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    input[type="submit"] {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }
  }

  .list-group {
    color: var(--bs-body-color) !important;
    font-weight: var(--bs-body-font-weight-medium);

    input[type="search"] {
      max-width: 300px;
      width: 100%;
      margin-left: 0;
      margin-bottom: 1.5rem;
      display: block;
    }

    // .list-group-item {
    //   border-radius: 0px;
    //   border: none;
    //   padding: 0;
    //   background-color: transparent;
    // }
    p {
      display: inline-block;
    }

    > * {
      .list-group-item {
        padding: 1.5rem;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        display: block;
      }

      &:not(:last-child) {
        .list-group-item {
          border-bottom: 1px solid var(--bs-primary-lighter);
        }
      }

      &:last-child {
        .list-group-item {
          padding-bottom: 0;
        }
      }

      &:nth-of-type(even) .list-group-item {
        background-color: var(--bs-primary-lightest);
      }
    }

    .btn {
      background-color: transparent;
      color: var(--bs-gray-dark);
      padding: 0;
      margin: 0 0.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        box-shadow: none;
        color: var(--bs-primary);
      }
    }
  }

  .table-body {
    border-top: 1px solid var(--bs-primary-lighter) !important;
  }

  .table-header {
    .table-row {
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;

      @include media-breakpoint-up(lg) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }

  *[class*="table-cell"] {
    flex-basis: 10%;
    max-width: 10%;
    min-width: 100px;
    font-size: var(--bs-font-size-small);

    label {
      font-weight: lighter;
      color: var(--bs-secondary);
    }

    h1 {
      font-size: var(--bs-font-size-larger);
      margin-bottom: 0;
    }

    h2 {
      font-size: var(--bs-font-size-large);
      margin-bottom: 0;
    }

    h3 {
      font-size: var(--bs-font-size-body);
      margin-bottom: 0;
    }

    h4 {
      font-size: var(--bs-font-size-small);
      margin-bottom: 0;
    }

    h5 {
      font-size: var(--bs-font-size-small);
      margin-bottom: 0;
    }

    h6 {
      font-size: var(--bs-font-size-smaller);
      margin-bottom: 0;
    }

    &.table-cell__title {
      h4 {
        font-size: var(--bs-font-size-large);
      }
    }

    &.table-cell__controls {
      max-width: 80px;
      flex: 80px 0 0 !important;

      @include media-breakpoint-up(xxl) {
        max-width: 150px;
        flex: 150px 0 0 !important;
      }
    }

    &.table-cell__details {
      max-width: 100%;
      flex: 1 0 0 !important;
      padding-right: 1rem;

      label {
        font-size: var(--bs-font-size-smallest);
        color: var(--bs-body-color-light);
      }
    }

    &.table-cell__status {
      max-width: 80px;
      flex: 80px 0 0 !important;

      @include media-breakpoint-up(xxl) {
        max-width: 150px;
        flex: 150px 0 0 !important;
      }
    }

    &.table-cell__updated {
      max-width: 150px;
      flex: 150px 0 0 !important;
    }

    &.table-cell__title {
      max-width: 100%;
      flex: 1 0 0 !important;
      padding-right: 1rem;
    }

    &.table-cell__file-number {
      max-width: 200px;
      flex: 200px 0 0 !important;
    }

    &.table-cell__user {
      max-width: 200px;
      flex: 200px 0 0 !important;
    }

    &.table-cell__other-details {
      max-width: 200px;
      flex: 200px 0 0 !important;
    }
  }

  .table-header,
  .table-body {
    min-width: 150%;

    @include media-breakpoint-up(xxxl) {
      min-width: 100%;
    }
  }

  .table-row__frame {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: block;
    width: 100%;
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-up(lg) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--bs-primary-lighter);
    }

    &:nth-of-type(even) {
      background-color: var(--bs-primary-lightest);
    }
  }

  .table-row {
    // width: calc( 100% + 3rem);
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: -1rem;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;

    > div:not(.table-cell__details) {
      margin-bottom: 1rem;
    }
  }

  .table-sub-row {
    margin-bottom: -0.5rem;

    > div {
      margin-bottom: 0.5rem;
    }
  }

  a[data-bs-toggle="collapse"] {
    &[aria-expanded="true"] {
      i {
        display: inline-block;
        vertical-align: middle;
        transform: rotate(45deg);
        transform-origin: center;
        transition: all 0.4s;
      }
    }
  }

  .dashboard-tiles {
    .card {
      min-height: 100%;
    }
  }

  .dashboard-tile {
    .card {
      margin-bottom: 0;
    }

    .activity.details {
      padding-left: calc(1.5rem - 15px);
      padding-right: calc(1.5rem - 15px);

      //width: calc( 100% + 3rem );
      div {
        &:last-child {
          margin-bottom: -1px;
        }
      }

      &:nth-of-type(even) {
        background-color: var(--bs-primary-lightest);
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--bs-primary-lighter);
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }

  .horizontal-line {
    background: linear-gradient(var(--bs-primary-lighter), var(--bs-primary-lighter)) no-repeat center/2px 100%;
  }

  div[data-status] {
    position: relative;
    z-index: auto;
    background-color: transparent !important;
    padding-left: 1rem;

    a {
      margin-left: 0.25rem;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 7px;
      height: 7px;
      border-radius: 7px;
      background-color: var(--bs-gray-dark);
      color: var(--bs-gray-dark);
      box-shadow: 0 0 15px 0 currentColor;
    }

    &[data-status="bg-success"]:before {
      background-color: var(--bs-success);
      color: var(--bs-success);
    }

    &[data-status="bg-danger"]:before {
      background-color: var(--bs-danger);
      color: var(--bs-danger);
    }

    &[data-status="bg-info"]:before,
    &[data-status="bg-warning"]:before {
      background-color: var(--bs-warning);
      color: var(--bs-warning);
    }
  }

  .vertical-text {
    writing-mode: vertical-lr;
    text-orientation: sideways;
  }

  /* Flex gap */
  .gap-largest {
    gap: var(--bs-spacer-xlarge);
  }

  .gap-larger {
    gap: var(--bs-spacer-larger);
  }

  .gap-large {
    gap: var(--bs-spacer-large);
  }

  .gap-medium {
    gap: var(--bs-spacer);
  }

  .gap-small {
    gap: var(--bs-spacer-small);
  }

  .gap-smaller {
    gap: var(--bs-spacer-smaller);
  }

  .gap-smallest {
    gap: var(--bs-spacer-smallest);
  }

  .gap-none {
    gap: 0;
  }
}
