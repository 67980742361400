.workflow-template--folders-panel {
  .folder-trigger-panel {
    .details {
      padding: var(--bs-spacer-smaller);
      border: 1px solid var(--bs-white);
      background: linear-gradient(90deg, var(--bs-white), var(--bs-primary-lightest) 75%, var(--bs-primary-lighter));
      &:hover {
        background-color: var(--bs-secondary-light);
      }
    }
  }
}
