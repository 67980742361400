body[data-leftbar-theme="dark"] {
  .app-navigation {
    --bs-white: var(--bs-dark);
    --bs-primary: var(--bs-light);
    --bs-primary-light: var(--bs-gray-700);
    --bs-body-color: var(--bs-light);
    i:before {
      color: var(--bs-primary);
    }
    ul.dropdown-menu {
      li.dropdown-item {
        --bs-body-color: var(--bs-dark);
      }
    }
  }
}

.side-nav {
  .projects {
    max-height: 60%;
    overflow-y: auto;
  }
  .tree {
    line-height: 1.8;
    width: max-content;
    --tree-spacer: 1.5rem;
    overflow-y: hidden;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    .depth-1 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .depth-2 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .depth-3 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .depth-4 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .depth-5 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .depth-6 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .depth-7 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .depth-8 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .depth-9 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .depth-10 {
      padding-left: 0;
      margin-left: var(--tree-spacer);
    }
    .tree-table__header {
      position: relative;
      z-index: 1;
      background-color: inherit;
      border: 0;
    }
    .tree-table__parent-row {
      padding-left: 1.5rem;
      padding-right: 0rem;
      &:hover {
        background-color: var(--bs-primary-lightest);
        span {
          background-color: var(--bs-primary-lightest);
        }
      }
      .tree-table__child-row {
        padding-left: 2.5rem;
      }
    }
    .tree-table__row {
      position: relative;
      width: 100%;
      .tree-table__row {
        border-left: 0;
      }
      &.even {
        background-color: transparent;
      }
      &.active .tree-table__title {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: -12px;
          left: -1.5rem;
          height: 100%;
          border-bottom: 1px solid var(--bs-primary);
          width: 1rem;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 50%;
          left: -1.5rem;
          height: 1000px;
          border-left: 1px solid var(--bs-primary);
          width: 1rem;
          margin-top: -1px;
        }
      }
    }
    .tree-table__child-row {
      position: relative;
      width: max-content;
      overflow: hidden;
      &:before {
        content: "";
        height: 98%;
        width: 1px;
        position: absolute;
        top: 1%;
        left: var(--tree-spacer);
        background-color: var(--bs-primary-lighter);
      }
      .tree-table__child-row {
        --tree-spacer: 3rem;
        .tree-table__child-row {
          --tree-spacer: 4.5rem;
          .tree-table__child-row {
            --tree-spacer: 6rem;
            .tree-table__child-row {
              --tree-spacer: 7.5rem;
              .tree-table__child-row {
                --tree-spacer: 9rem;
                .tree-table__child-row {
                  --tree-spacer: 10.5rem;
                  .tree-table__child-row {
                    --tree-spacer: 12rem;
                    .tree-table__child-row {
                      --tree-spacer: 13.5rem;
                      .tree-table__child-row {
                        --tree-spacer: 15rem;
                        .tree-table__child-row {
                          --tree-spacer: 16.5rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .tree-table__child-row:hover {
      .tree-table__row {
        opacity: 0.33;
        &:hover {
          opacity: 1;
        }
      }
    }
    .tree-table__title {
      white-space: nowrap;
      position: relative;
      z-index: 0;
      span {
        background: var(--bs-body-bg);
      }
      &.expander > a[aria-expanded="true"] {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -1.5rem;
          height: 50%;
          border-bottom: 1px dashed var(--bs-primary);
          width: 1rem;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 50%;
          left: -1.5rem;
          height: 1000px;
          border-left: 1px dashed var(--bs-primary);
          width: 1rem;
          margin-top: -1px;
        }
      }
      &.expander {
        a {
          color: var(--bs-primary);
          &a[aria-expanded="true"] {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
    .tree-table__columns {
      .column {
        border: 1px solid var(--bs-white);
        background-color: var(--bs-body-bg);
      }
    }
  }
}
