.folder-view {
  .folder-item {
    border-radius: var(--bs-radius);
    padding: var(--bs-spacer-smallest);
    background-color: var(--bs-primary-lighter);
  }
}

ul.folder-contents {
  li[hidden] {
    display: none !important;
  }
  li {
    .checkbox {
      width: var(--bs-spacer-large);
    }
    .no-checkbox {
      padding-inline-start: var(--bs-spacer-xlarge);
    }
    .status {
      flex-basis: 8rem;
    }
    .toolbar {
      width: var(--bs-spacer-large);
    }
  }
}
