@media print {
  .page-break {
    page-break-after: always;
  }
  table.table {
    thead {
      th {
        text-align: left;
      }
    }
  }
}
