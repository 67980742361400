.c8o2 .user-story {
  background-color: #333333;
  color: #06e027;
  padding: 2rem;
  border-radius: 0.25rem;
}

.c8o2 .user-story h1 {
  color: #08ff2d;
  margin-top: 1 rem;
  margin-bottom: 0.8rem;
}

.c8o2 .user-story h2 {
  color: #05b520;
  margin-top: 0.6rem;
  margin-bottom: 0.4rem;
  text-decoration: underline;
  font-family: var(--bs-font-fixed-width);
}

.c8o2 .user-story pre {
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  margin-left: 2rem;
  margin-right: 0.2rem;
  text-wrap: wrap;
  overflow: hidden;
  color: #06e027;
}
