// Ensure any changes to column sizes are updated in issue_sheet_helper
.issue-sheet {
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;

  .issue-sheet--header {
    border-top: 0.1rem solid var(--bs-secondary);
    padding-top: 0.5rem;
  }
  .issue-sheet--row {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: max-content;

    &:nth-of-type(even) {
      background-color: var(--bs-primary-lightest);
    }
  }

  .issue-sheet--name {
    min-width: 16rem;
    width: 16rem;
    justify-content: start;
  }
  .issue-sheet--details {
    min-width: 32rem;
    width: 32rem;
    justify-content: start;
  }
  .issue-sheet--column {
    min-width: 4rem;
    width: 4rem;
    justify-content: end;
    margin-block-start: 0.2rem;
    margin-block-end: 0.2rem;
  }
  .issue-sheet--date {
    writing-mode: vertical-lr;
    text-orientation: sideways;
    margin-block-start: 0;
    margin-block-end: 0;
  }
}
