table.table {
  border: none;
  .table-light {
    tr {
      th {
        color: var(--bs-primary);
        background-color: var(--bs-body-bg);
      }
      td {
        color: var(--bs-body-color);
      }
      &:nth-of-type(2n+1) {
        --bs-table-accent-bg: var(--bs-primary-lightest);
      }
      --bs-table-accent-bg: var(--bs-white);
    }
    color: var(--bs-white);
  }
}