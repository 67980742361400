@import "bootstrap/scss/bootstrap";
.pdf-viewer {
  position: relative;
  z-index: 100;
  height: calc(100vh - var(--header-height));
  width: 100%;

  .document-navigation-bar {
    position: fixed;
    left: var(--sidebar-width);
    top: var(--header-height);
    width: 100%;
    z-index: 100;
    background-color: var(--bs-primary-lighter);
    color: #333;
    opacity: 0.9;
  }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    &.pdf-canvas {
      z-index: 10;
    }
    &.annotation-canvas {
      z-index: 20;
    }
    &.drag-canvas {
      z-index: 30;
    }
  }
}
