:root,
.c8o2 {
  --bs-nav-tabs-link-active-color: var(--bs-secondary);
  --bs-nav-tabs-link-active-bg: var(--bs-primary-lightest);

  .nav-tabs {
    background-color: var(--bs-primary-lightest);
    .nav-item {
      border-right: 1px solid var(--bs-primary-lighter);
      border-top: 1px solid var(--bs-primary-lighter);
      border-left: 1px solid var(--bs-primary-lighter);
    }
    .nav-link {
      border: none !important;
      color: var(--bs-primary-light);
      font-weight: lighter;
      &:hover {
        background-color: var(--bs-primary-light);
        color: var(--bs-light);
      }
      &.active {
        background-color: var(--bs-primary-light);
        color: var(--bs-light);
        font-weight: normal;
        a {
          color: var(--bs-light);
        }
        &:hover {
          background-color: var(--bs-primary);
          color: var(--bs-light);
        }
      }
    }
  }
  .nav-pills {
    border-bottom: none !important;
    .nav-item {
      border-right: 1px solid var(--bs-secondary-lighter);
    }
    .nav-link {
      color: var(--bs-primary);
      font-weight: lighter;
      &:hover {
        background-color: var(--bs-primary-lighter);
        color: var(--bs-primary);
      }
      &.active {
        background-color: var(--bs-primary-lighter) !important;
        color: var(--bs-secondary) !important;
      }
    }
  }
}
