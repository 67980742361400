.grid {
  font-size: 0.8rem;
  .grid__header {
    border-bottom: 1px solid var(--bs-secondary);
    padding: 0.2rem;
    font-weight: var(--bs-body-font-weight-medium);
    color: var(--bs-secondary);
    a {
      text-decoration: none;
      color: var(--bs-secondary);
    }
  }
  .grid__container-row {
    border-top: 1px solid var(--bs-primary-lighter);
    overflow-x: hidden;
    &:first-child {
      margin-top: 0;
      border-top: 0;
    }
  }
  .grid__columns {
    margin-bottom: -0.5rem;
    padding-bottom: 0.8rem;
  }
  .grid__column {
    display: block;
    label + span {
      margin: 0.25rem 0 0.5rem;
    }
    &:hover {
      background-color: var(--bs-primary-lighter);
    }
  }
  .grid__select-column {
    width: 2rem;
  }
  .grid__actions-column {
    width: 6rem;
  }
  .grid__row {
    &.selected {
      background-color: var(--bs-secondary-light);
      padding: 2px;
    }
  }
  .grid__footer {
    border-top: 1px solid var(--bs-primary-lighter);
    padding-top: 0.5rem;
  }
}
